import React, { useState, useEffect, useCallback, useRef } from 'react';
import MyButton from 'components/ui/Button/MyButton';
import { Input, Card, Checkbox, Select, Spin } from 'antd'
import { ADMIN_PASSWORD_POLICY } from 'constants';
import ToastMsg from "components/common/ToastMsg";
import { NextAttemptsInEnum } from 'components/common/enum';
import useGetData from "components/common/useGetData";
import { getPasswordPolicy } from "hooks/api/adminApi/employerAPI";
import {checkUserPermissionFunc} from "components/common/CommonFuntion";

const PasswordPolicy = () => {
    const companyid = sessionStorage.getItem("userId")
    const [formData, setFormData] = useState({
        id: null,
        company: companyid,
        min_length: 0,
        max_length: 0,
        min_number: 0,
        max_number: 0,
        min_lower: 0,
        max_lower: 0,
        min_upper: 0,
        max_upper: 0,
        min_chars: 0,
        max_chars: 0,
        password_combination: false,
        password_repetation: false,
        personal_information: false,
        expairy_alert: false,
        expairy_period: 0,
        alert_before: 0,
        login_attempts: false,
        max_attempts: 0,
        next_attempt: "0 mins",
        session_time_out: false,
        time_out_value: "0 mins",
        email: "",
        reset_list: true
    });
    const [loadings, setLoadings] = useState(false);
    const prevPropsRef = useRef();
    const [data, _message, refetch, loading] = useGetData(getPasswordPolicy);
    const userPermission = checkUserPermissionFunc("administrator", "Password Policy", "SubMenu");

    useEffect(() => {
        if (data !== null &&
            data.length > 0 &&
            Object.keys(data[0]).length !== 0 &&
            JSON.stringify(prevPropsRef.current) !== JSON.stringify(data[0]) &&
            JSON.stringify(formData) !== JSON.stringify(data[0])) {
            let Data = data[0];
            setFormData((prevFormData) => ({
                ...prevFormData,
                id: Data.id,
                company: Data.company_id ? Data.company_id : companyid,
                min_length: Data.min_length ? Data.min_length : 0,
                max_length: Data.max_length ? Data.max_length : 0,
                min_number: Data.min_number ? Data.min_number : 0,
                max_number: Data.max_number ? Data.max_number : 0,
                min_lower: Data.min_lower ? Data.min_lower : 0,
                max_lower: Data.max_lower ? Data.max_lower : 0,
                min_upper: Data.min_upper ? Data.min_upper : 0,
                max_upper: Data.max_upper ? Data.max_upper : 0,
                min_chars: Data.min_chars ? Data.min_chars : 0,
                max_chars: Data.max_chars ? Data.max_chars : 0,
                password_combination: Data.password_combination,
                password_repetation: Data.password_repetation,
                personal_information: Data.personal_information,
                expairy_alert: Data.expairy_alert,
                expairy_period: Data.expairy_period ? Data.expairy_period : 0,
                alert_before: Data.alert_before ? Data.alert_before : 0,
                login_attempts: Data.login_attempts,
                max_attempts: Data.max_attempts ? Data.max_attempts : 0,
                next_attempt: Data.next_attempt !== "00:00:00" ? Data.next_attempt : "0 mins",
                session_time_out: Data.session_time_out,
                time_out_value: Data.time_out_value !== "00:00:00" ? Data.time_out_value : "0 mins",
                email: Data.email ? Data.email : "",
                reset_list: Data.reset_list ? Data.reset_list : false
            }));
            prevPropsRef.current = Data;
            // session data set
            let userPasswordPolicy = JSON.parse(sessionStorage.getItem("passwordPolicy"));
            let passwordData = Object.keys(userPasswordPolicy)?.length > 0 ? userPasswordPolicy : {};
            if (JSON.stringify(passwordData) !== JSON.stringify(Data)) {
                let dataList = Object.keys(Data)?.length > 0 ? Data : {};
                sessionStorage.setItem("passwordPolicy", JSON.stringify(dataList));
            }
        }
    }, [data]);

    const handleChange = (name, value) => {
        let updatedFormData = { ...formData };
        if (name.includes("min")) {
            if (value === "" || (parseInt(value) >= 0 && parseInt(value) <= 15)) {
                updatedFormData[name] = value;
            }
        } else if (name.includes("max")) {
            if (value === "" || (parseInt(value) >= 0 && parseInt(value) <= 15)) {
                updatedFormData[name] = value;
            }
        } else if (name === "expairy_period") {
            if (value === "" || (parseInt(value) >= 1 && parseInt(value) <= 365)) {
                updatedFormData["expairy_period"] = value;
            }
        } else if (name === "max_attempts") {
            if (value === "" || (parseInt(value) >= 1 && parseInt(value) <= 8)) {
                updatedFormData["max_attempts"] = value;
            }
        } else {
            updatedFormData[name] = value;
        }
        setFormData(updatedFormData);
    };

    const handleKeyDown = (e) => {
        // Allow only numbers, backspace, and delete keys
        if (!/^[\d.]$/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && e.key !== "Home" && e.key !== "End") {
            e.preventDefault();
        }
        // Get the current value and the expected value after the key press
        const currentValue = e.target.value;
        const newValue = currentValue + e.key;
        // Prevent input if the result would exceed 15 and the field is `max_length` or `min_length`
        if ((parseInt(newValue, 10) > 15) && (e.target.name === "max_length" || e.target.name === "min_length")) {
            e.preventDefault();
        }
    }
    
    const renderBorderInput = (label, statekey) => {
        return (
            <Input
                readOnly = {userPermission?.edit !== undefined ? !userPermission?.edit : false}
                bordered={true}
                style={styles.inputBox}
                autoComplete='off'
                size='large'
                placeholder={label}
                name={statekey}
                value={formData[statekey] ? formData[statekey] : ""}
                onChange={(e) => handleChange(e.target.name, +e.target.value)}
                onKeyDown={handleKeyDown}
                maxLength={ (statekey === "min_length" || statekey ===  "max_length") ? 2 : 1}
            />
        )
    }

    const renderInput = (label, statekey) => {
        return (
            <div>
                <p className='password-checkbox'>{label}</p>
                <Input
                readOnly = {userPermission?.edit !== undefined ? !userPermission?.edit : false}
                bordered={false}
                style={styles.textfield}
                autoComplete='off'
                type={statekey === "email" ? "email" : "text"}
                name={statekey}
                value={formData[statekey] ? formData[statekey] : ""}
                onChange={(e) => handleChange(e.target.name, statekey === "email" ? e.target.value : +e.target.value)}
                suffix={(statekey === "expairy_period" || statekey === "alert_before") ? "Days" : ""}
                onKeyDown={statekey === "email" ? null : handleKeyDown}
            />
            </div> 
        )
    }

    const renderCheckbox = (label, statekey) => {
        return (
            <Checkbox
                // disabled = {userPermission?.edit !== undefined ? !userPermission?.edit : false}
                disabled = {true}
                checked={formData[statekey]}
                className='mb-2'
                onChange={(e) => handleChange(statekey, e.target.checked)}
            >
                <span className='password-checkbox'>{label}</span>
            </Checkbox>
        )
    }

    const renderSelect = (label, statekey) => {
        return (
            <div>
                <p className='password-checkbox'>{label}</p>
                <Select
                style={styles.textfield}
                value={formData[statekey] ? formData[statekey] : ""}
                name={statekey}
                onChange={(value) => handleChange(statekey, value)}
                disabled = {userPermission?.edit !== undefined ? !userPermission?.edit : false}
            >
                {NextAttemptsInEnum.map((option) => (
                    <Select.Option key={option.value} value={option.value}>{option.text}</Select.Option>
                ))}
            </Select>
            </div>
        )
    }

    const handleClear = () => {
        setFormData((prevState) => ({
            ...prevState,
            min_length: 0,
            max_length: 0,
            min_number: 0,
            max_number: 0,
            min_lower: 0,
            max_lower: 0,
            min_upper: 0,
            max_upper: 0,
            min_chars: 0,
            max_chars: 0,
            password_combination: false,
            password_repetation: false,
            personal_information: false,
            expairy_alert: false,
            expairy_period: 0,
            alert_before: 0,
            login_attempts: false,
            max_attempts: 0,
            next_attempt: "0 mins",
            session_time_out: false,
            time_out_value: "0 mins",
            email: "",
            reset_list: true
        }))
    }
     
    const validatePasswordVal = () => {
        let msg = "";
        if (!formData.min_length || !formData.max_length || !formData.min_number || !formData.max_number || !formData.min_lower
            || !formData.max_lower || !formData.min_upper || !formData.max_upper || !formData.min_chars || !formData.max_chars) {
            msg = "Kindly enter all the password character complexity"
        }
        return msg;
    } 
    
    const handleSubmit = useCallback(() => {
        const authtoken = sessionStorage.getItem("token");
        let isValid = validatePasswordVal();
        let updatedFormData = { ...formData };
        updatedFormData["next_attempt"] = updatedFormData["next_attempt"] !== "0 mins" ? updatedFormData["next_attempt"] : '00:00:00';
        updatedFormData["time_out_value"] = updatedFormData["time_out_value"] !== "0 mins" ? updatedFormData["time_out_value"] : '00:00:00';
        updatedFormData["reset_list"] = updatedFormData["reset_list"] ? "mail" : null
        if(isValid === ""){
            try {
                setLoadings(true)
                delete formData.reset_list;
                fetch(ADMIN_PASSWORD_POLICY, {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `token ${authtoken}`
                    },
                    body: JSON.stringify(updatedFormData)
                })
                    .then((response) => response.json())
                    .then(async data => {
                        if (data.status === "success") {
                            setLoadings(false);
                            refetch();
                            ToastMsg("success", data.message);
                        }
                        else if (data.status === "fail") {
                            ToastMsg("error", data.message);
                            setLoadings(false);
                        }
                    })
                    .catch(error => {
                        setLoadings(false);
                        ToastMsg("error", error);
                    });
            } catch (error) {
                ToastMsg("error", error.message);
            }
        } else {
            ToastMsg("error", isValid);
        }
    }, [formData]);

    return (
        <Card style = {{height: "100%", overflow: "hidden", position: "relative"}}>
            {loading && (
                <div className="loaderOverlay">
                    <Spin />
                </div>
            )}
            <p className='runpayRoll_Txt'>Password Policy</p>
            <div className='overflow-y-auto' style = {{height: "81vh"}}>
                <p className='password-tlt' style ={{color: "#183433"}}>Password Character Complexity</p>
                <div className='w-full grid grid-cols-3 gap-12'>
                    <div className='grid grid-rows-5 grid-cols-1 gap-5'>
                        <div className='flex justify-between items-center'>
                            <p className='password-checkbox'>Minimum Length</p>
                            {renderBorderInput("", "min_length")}
                        </div>
                        <div className='flex justify-between items-center'>
                            <p className='password-checkbox '>Minimum Number</p>
                            {renderBorderInput("", "min_number")}
                        </div>
                        <div className='flex justify-between items-center'>
                            <p className='password-checkbox'>Minimum Lower Case</p>
                            {renderBorderInput("", "min_lower")}
                        </div>
                        <div className='flex justify-between items-center'>
                            <p className='password-checkbox'>Minimum Upper Case</p>
                            {renderBorderInput("", "min_upper")}
                        </div>
                        <div className='flex justify-between items-center'>
                            <p className='password-checkbox'>Minimum Character Allowed</p>
                            {renderBorderInput("", "min_chars")}
                        </div>
                    </div>
                    <div className='grid grid-rows-5 grid-cols-1 gap-5'>
                        <div className='flex justify-between items-center'>
                            <p className='password-checkbox'>Maximum Length</p>
                            {renderBorderInput("", "max_length")}
                        </div>
                        <div className='flex justify-between items-center'>
                            <p className='password-checkbox'>Maximum Number</p>
                            {renderBorderInput("", "max_number")}
                        </div>
                        <div className='flex justify-between items-center'>
                            <p className='password-checkbox'>Maximum Lower Case</p>
                            {renderBorderInput("", "max_lower")}
                        </div>
                        <div className='flex justify-between items-center'>
                            <p className='password-checkbox'>Maximum Upper Case</p>
                            {renderBorderInput("", "max_upper")}
                        </div>
                        <div className='flex justify-between items-center'>
                            <p className='password-checkbox'>Maximum Character Allowed</p>
                            {renderBorderInput("", "max_chars")}
                        </div>
                    </div>
                    <div className='grid grid-row-3'>
                        <div>
                            {renderCheckbox("Password Combination", "password_combination")}
                            <div className='border alert_txt p-1'>
                                * Please activate this checkbox if your password includes a combination of alphabets,numbers and special characters.
                            </div>
                        </div>
                        {/* <div>
                            {renderCheckbox("Password Repetition", "password_repetation")}
                            <div className='border alert_txt p-1'>
                                * Please activate this checkbox to prevent the reuse of previously used passwords.
                            </div>
                        </div> */}
                        {/* <div>
                            {renderCheckbox("Personal Information in Password", "personal_information")}
                            <div className='border alert_txt p-1'>
                                <p> * Please activate this checkbox to prevent personal details from being included in your passwords.</p>
                            </div>
                        </div> */}
                         <div>
                            <p className="password-checkbox">Forgot Password - Reset OTP sent to Mail</p>
                            {renderCheckbox("Mail", "reset_list")}
                        </div>
                    </div>
                </div>
                {/* <p className='password-tlt' style ={{color: "#183433"}}>Additional Configuration</p> */}
                <div className='w-full' style={{ margin: "0.5vw 0vw" }}>
                    {/* <div className='w-full grid grid-cols-3 gap-x-12 gap-y-6 mb-2'>
                        {renderCheckbox("Password Expiry Alert", "expairy_alert")}
                        {renderInput("Password Expiry Period", "expairy_period")}
                        {renderInput("Alert Before", "alert_before")}
                    </div>
                    <div className='w-full grid grid-cols-3 gap-x-12 gap-y-6 mb-2'>
                        {renderCheckbox("Login Attempts", "login_attempts")}
                        {renderInput("Login Max Attempts", "max_attempts")}
                        {renderSelect("Next Attempts In", "next_attempt")}
                    </div>
                    <div className='w-full grid grid-cols-3 gap-x-12 gap-y-6 mb-1'>
                        {renderCheckbox("Session Time-Out", "session_time_out")}
                        {renderSelect("Session Timeout", "time_out_value")}
                        {renderInput("Mail Id", "email")}
                    </div>
                    <div className='w-full grid grid-cols-3 gap-x-12 gap-y-6'>
                        <div className='col-span-2'></div>
                        <div className='border alert_txt p-1'>
                           When the maximum number of attempts is reached, an email will be sent to the email address entered above.
                        </div>
                    </div> */}
                    <div className='flex justify-end '>
                       
                        <div style = {{marginTop: "2vw"}}>
                            <MyButton htmlType="button" label="Clear" onClick={handleClear} outlined = {true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />
                            <MyButton htmlType="submit" label="Update" onClick={handleSubmit} 
                            disabled = {userPermission?.edit !== undefined ? !userPermission?.edit : false} loading={loadings} paddingX={"0 1.2vw"} />
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
}

const styles = {
    inputBox: {
        width: "2.2vw",
        border: "1px solid black",
        textAlign: "center",
        padding: "1px"
    },
    textfield: {
        width: "80%",
        borderBottom: "1px solid black",
        borderRadius: 0,
        outline: "none",
    }
}

export default PasswordPolicy
