import React, { useEffect, useState } from "react";
import { Card, Space, Table } from 'antd';
import dayjs from 'dayjs';
import MyPresentButton from 'components/ui/Button/MyPresentButton';
import useGetEmployeeAttendnceLog from './useGetEmployeeAttendanceLog';
import AttendanceFilter from 'pages/attendance/AttendanceFilters';
import CommonTblHeader from "components/common/CommonTblHeader";
import ToastMsg from "components/common/ToastMsg";
import { EMPLOYEE_ATTENDANCE_LOG } from "constants";
// import { checkUserPermissionFunc } from "components/common/CommonFuntion";
import { EmployeeDetails } from "components/common/CommonComponent";
import { useNavigate} from 'react-router-dom';
import { getDayWiseAttendanceByQuery } from "hooks/api/attendanceApi/dayWiseAttendanceApi";
import EditDayWiseAttendance from "../dayWiseAttendance/EditDayWiseAttendance";
import useApiData from "components/common/useGetApiData";

const initialData = {
  employee_name: "",
  designation_name: "",
  department_name: "",
  status: ""
}

const statusConfig = {
  "present": { label: "P", bgColor: "#28A745", tipLabel: "Present" },
  "absent": { label: "A", bgColor: "#A72828", tipLabel: "Absent" },
  "holiday": { label: "HD", bgColor: "#EEBA35", tipLabel: "Holiday" },
  "on-duty": { label: "OD", bgColor: "#3595EE", tipLabel: "On Duty" },
  "week-off": { label: "WO", bgColor: "#F9827A", tipLabel: "WeekOff" },
  "compensation": { label: "Comp", bgColor: "#FEB8C3", tipLabel: "Compensation" },
  "WFH": { label: "WFH", bgColor: "#8DD6F5", tipLabel: "Work From Home" },
  "leave": { label: "L", bgColor: "#CCA6FC", tipLabel: "Leave" },
  "N/A": { label: "N/A", bgColor: "#b4b1b1", tipLabel: "Not Available" },
};

const AttendanceLog = () => {
  const query = "";
  const pageSize = 10;
  const dateFormat = "YYYY-MM-DD";
  const authtoken = sessionStorage.getItem("token");
  const today = new Date().toLocaleDateString('en-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).split('/').reverse().join('-');
  const is_super_admin = JSON.parse(sessionStorage.getItem("is_super_admin"));
  const is_admin = JSON.parse(sessionStorage.getItem("is_admin"));

  let isEditAccess = (is_super_admin || is_admin);

  let dateString;
  const [editVisible, setEditVisible] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [sorting, setSorting] = useState("");
  const [selectedDetails, setSelectedDetails] = useState({});
  const [dataUpdated, setDataUpdated] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterDatas, setFilterDatas] = useState({ initialData });
  const [reorderedColumns, setReorderedColumns] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs(today, dateFormat));
  const tableContainerHeight = "72vh";

  const currentMonth = new Date().getMonth();
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [daysInMonth, setDaysInMonth] = useState(new Date(currentYear, currentMonth + 1, 0).getDate());
  const [selectedmonth, setSelectedMonth] = useState(currentMonth + 1);
  const [dynamicColumns, setDynamicColumns] = useState([]);

  useEffect(() => {
    if (!authtoken) {
      window.location.href = '/';
    }
  }, [authtoken]);

  const navigate = useNavigate();

  // const userPermission = checkUserPermissionFunc("attendance", "Attendance Log", "SubMenu");

  // const [data, pageInfo, loading, _message, refetch] =
  // useApiData(useGetEmployeeAttendnceLog, {selectedDate, pageNo, pageSize, query, searchText, sorting});

  const [data, pageInfo, loading, _message, refetch] =
  useGetEmployeeAttendnceLog(selectedDate, pageNo, pageSize, query, searchText, sorting);


  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  const getEmployeeAttendanceById = async (id, date) => {
    try {
      const apiData = await getDayWiseAttendanceByQuery(`employee_id=${id ? id : null}&date=${date ? date : null}`);
      if (apiData && apiData.status === "success" && apiData.data && apiData.data.result && apiData.data.result.length > 0 ) {
        setSelectedDetails({...apiData.data.result[0]});
      } else {
        setSelectedDetails({});
      }
    } catch (error) {
      setSelectedDetails({});
    }
  }

  const handleViewDetails = (employeeId, data) => {
    if (employeeId) {
      setEditVisible(true);
      getEmployeeAttendanceById(employeeId, data);
    }
  }

  useEffect(() => {
    const daysInMonth = dayjs(`${currentYear}-${selectedmonth}`).daysInMonth();
    const newColumns = [];

    // Generate dynamic columns for each day of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const columnDate = dayjs(`${currentYear}-${selectedmonth}-${day}`).format('YYYY-MM-DD');
      const columnConfig = {
        title: dayjs(columnDate).format('DD MMM'),
        dataIndex: columnDate,
        key: columnDate.toString(),
        render: (text, record) => {
          // Extract attendance data for the specific day
          const attendanceData = record.attendance_dict.find(item => item.date === columnDate);
          const status = attendanceData ? attendanceData.status : 'N/A';
          const statusInfo = status ? statusConfig[status] : statusConfig['N/A'];
          let { employee_details } = record;
          return <Space size="middle">
            <MyPresentButton
              label={statusInfo?.label}
              bgColor={statusInfo?.bgColor}
              tooltipLabel = {statusInfo?.tipLabel}
              onClick={() => isEditAccess ? handleViewDetails(employee_details?.id, columnDate) : ""}
            />
          </Space>
        },
      };
      newColumns.push(columnConfig);
    }

    // Update the state with the new columns
    setDynamicColumns(newColumns);
  }, [currentYear, selectedmonth, data]);

  useEffect(() => {
    if (_message && _message.isErrorMsg && _message.errorMsgText) {
      ToastMsg('error', _message.errorMsgText);
    }
  }, [_message.errorMsgText]);

  // Date changes start 
  // Filter the data based on the selected date
  const handleDateChange = async (date) => {
    setSelectedDate(date);
    dateString = dayjs(date).format("YYYY-MM-DD");
    setSelectedMonth(dayjs(date).format("MM"))
    setDataUpdated(!dataUpdated);
    setCurrentYear(new Date(dateString).getFullYear());
    setDaysInMonth(new Date(currentYear, dayjs(date).format("MM"), 0).getDate());
    refetch(dateString, 1, pageSize, query, searchText, sorting);
  };

  const handleSearch = () => {
    setEditVisible(false)
    setSelectedDetails({});
    setPageNo(1);
    refetch(selectedDate, 1, pageSize, query, searchText, sorting);
    setDataUpdated(!dataUpdated);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""
    refetch(selectedDate, pagination.current, pageSize, query, searchText, sortQuery);
    setSorting(sortQuery);
    setPageNo(pagination.current);
  }

  // filter start
  const renderFilter = (data) => {
    setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
    setPageNo(1);
    refetch(selectedDate,
      1,
      pageSize,
      `employee=${data.employee_name ? data.employee_name : ""}&designation=${data.designation_name ? data.designation_name : ""}&department=${data.department_name ? data.department_name : ""}&status=${data.status ? data.status : ""}`,
      searchText,
      sorting);
    setFilterVisible(false);
  }

  const ClosePopUp = () => {
    setFilterVisible(false);
  }

  const clearFilter = () => {
    setFilterDatas(initialData);
    setPageNo(1);
    refetch(
      selectedDate,
      1,
      pageSize,
      query,
      searchText,
      sorting);
  }
  //filter end

  const handleClick = (empData) => {
    // let { employee_details } = empData;
    // navigate('/attendance/attendancelog/chart', { state: { employeeId: employee_details?.id ? employee_details?.id : null } })
  };

  const handleCancel = () => {
    setEditVisible(false);
    setSelectedDetails({});
  };

  const handleSave = () => {
    navigate('/attendance/attendancelog');
    refetch(selectedDate, pageNo, pageSize, query, searchText, sorting);
  };

  const columns = [
    {
      title: "Employee Details",
      dataIndex: "employee_details",
      key: "employee_details",
      sorter: true,
      fixed: "left",
      width: 250,
      render: (_, record) => {
        let { employee_details } = record;
        let empDetails = {
          ...record,
          title: employee_details.title ? employee_details.title : "",
          first_name: employee_details.employee_name ? employee_details.employee_name : "",
          last_name: "",
          designation_id: {
            id: employee_details?.designation?.id ? employee_details?.designation?.id : "",
            designation_name: employee_details?.designation?.name ? employee_details?.designation?.name : ""
          },
          id: employee_details.employee_id,
          profile: employee_details.profile,
        }
        return (<span onClick={() => handleClick(record)} style={{ cursor: "pointer" }}>
          <EmployeeDetails details={empDetails} />
        </span>)
      }
    },
    {
      title: "Total Working days",
      dataIndex: "total_working_days",
      key: "total_working_days",
      sorter: true,
      fixed: "left",
      width: 180,
      align: "center",
    },
    {
      title: "Present",
      dataIndex: "present",
      key: "present",
      sorter: true,
      fixed: "left",
      width: 75,
      align: "center",
    },
    {
      title: "Absent",
      dataIndex: "absent",
      key: "absent",
      sorter: true,
      fixed: "left",
      width: 75,
      align: "center",
    },
    ...dynamicColumns
  ];

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search)
    setReorderedColumns(reorderedList)
  }

  const handleFilter = () => {
    setFilterVisible(true)
  }

  const queryString =
    "?page_no=" + pageNo +
    "&page_size=" + pageSize +
    "&employee=" + (filterDatas.employee_name ? filterDatas.employee_name : "") +
    "&designation=" + (filterDatas.designation_name ? filterDatas.designation_name : "") +
    "&department=" + (filterDatas.department_name ? filterDatas.department_name : "") +
    "&status=" + (filterDatas.status ? filterDatas.status : "") +
    "&search=" + searchText + (sorting ? `&${sorting}` : "");

  const styles = {
    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  }

  return (
    <Card className="h-full">
      <div className={"h-1/6"} >
        <CommonTblHeader
          pageName={"Attendance log"}
          pageInfo={pageInfo}
          handleSearch={handleSearch}
          onStateChange={handleStateChanges} handleFilter={handleFilter} columns={columns}
          url={EMPLOYEE_ATTENDANCE_LOG} query={queryString}
          isAddRequired={false} isDraftRequired={false}
          isDateRequired={true} handleDateChange={handleDateChange} selectedDate={selectedDate}
          format="MMMM YYYY" picker="month" isPrintRequired = {false}
          isExcelRequired = {false}
        />
      </div>
      <div className="h-5/6 flex justify-center items-center  mt-1">
        {filterVisible ?
          <div className="w-1/4 mt-5" style={styles.fltContainer}>
            <AttendanceFilter
              pageName={"AttendanceLog"}
              filterDatas={filterDatas}
              closeFilter={ClosePopUp}
              filteredData={renderFilter}
              clearData={clearFilter} />
          </div>
          : null}
        <div className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"} style={styles.fltContainer}>
        <Table
          columns={reorderedColumns?.length > 0 ? reorderedColumns : columns}
          dataSource={data?.length > 0 ? data : null}
          loading={loading}
          style = {{"--table-container-height": tableContainerHeight}}
          scroll={{ x: 3500, y: `calc(${tableContainerHeight} - 165px)` }}
          onChange={handleTableChange}
          pagination={{
            style: { marginRight: 20, padding: 0 },
            defaultPageSize: pageSize,
            showSizeChanger: false,
            showQuickJumper: true,
            total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
            pageSizeOptions: ["10", "20", "30"],
            pageSize,
            defaultCurrent: pageNo,
          }}
          key={dataUpdated ? "updated" : "not-updated"}
        />
        </div>
      </div>
      {(editVisible && Object.keys(selectedDetails).length > 0) ?
        <EditDayWiseAttendance
          editDetails={selectedDetails}
          onHide={handleCancel}
          onSave={handleSave}
          fromEdit={editVisible}
          isActive={isEditAccess}
        />
        : null}
    </Card>
  );
};

export default AttendanceLog;
