//API URL
// export const API_URL = "https://beta.api.hrbuddie.com";
export const API_URL = "https://qa.api.hrms.hrbuddie.com";
// export const API_URL = "https://dev.api.hrms.hrbuddie.com";

export const App_Version = 1.1

//export const REGISTER = `${API_URL}/auth/register`;
export const LOGIN_URL = `${API_URL}/authentication/login/`;
export const LOGOUT_URL = `${API_URL}/authentication/logout/`;
export const EMPLOYEE_TYPE = `${API_URL}/master/employee_type/`;
export const EMPLOYEE_GROUP = `${API_URL}/master/group/`;
export const EMPLOYER = `${API_URL}/administrator/company/`;
export const EMPLOYER_DASHBOARD = `${API_URL}/dashboard/employer/`;
export const EMPLOYEE_BAND = `${API_URL}/configuration/employee_band/`;
export const EMPLOYEE_LEAVE_POLICY = `${API_URL}/master/leave_type/`;
export const EMPLOYEE_DESIGNATION = `${API_URL}/master/designation/`;
export const EMPLOYEE_DEPARTMENT = `${API_URL}/master/departments/`;
export const HOLIDAY_SETUP = `${API_URL}/configuration/holiday/`;
export const ASSET_MANAGEMENT = `${API_URL}/master/asset_type/`;
export const MASTER_ASSET_CATEGORY = `${API_URL}/master/asset_category/`;
export const MASTER_ASSET_SUBCATEGORY = `${API_URL}/master/asset_sub_category/`;
export const FORM = `${API_URL}/configuration/form/`;
export const EMPLOYEE_SHIFT = `${API_URL}/configuration/shift/`;
export const EMPLOYEE_WEEK_OFF = `${API_URL}/configuration/weekoff/`;
export const EMPLOYEE_DOCUMENT = `${API_URL}/configuration/document/`;
export const EMPLOYEE = `${API_URL}/employee/`;
export const EMPLOYEE_PERSONAL_INFO = `${API_URL}/employee/info/`;
export const EMPLOYEE_DOCUMENT_INFO = `${API_URL}/employee/document/`;
export const EMPLOYEE_SALARY_INFO = `${API_URL}/employee/salary/`;
export const EMPLOYEE_INFO = `${API_URL}/master/employee/`;
export const EMPLOYEE_PROFILE = `${API_URL}/master/employee/profile/`;
export const EMPLOYEE_DOC = `${API_URL}/master/employee_document/`;
export const EMPLOYEE_EDUCATION = `${API_URL}/master/employee_education_info/`;
export const EMPLOYEE_PERSONAL_DOC = `${API_URL}/master/personal/docs/`;
export const EMPLOYEE_EXPERIENCE = `${API_URL}/master/employee_experience/`;
export const NOTICE_BOARD = `${API_URL}/master/notice/`;
export const EMPLOYEE_BASIC_INFO = `${API_URL}/master/employee/profile/basic_info/`;
export const EMPLOYEE_PROFESSIONAL_INFO = `${API_URL}/master/employee/profile/professional/`;
export const EMPLOYEE_BANK = `${API_URL}/master/employee/profile/bank/`;
export const EMPLOYEE_ATTENDANCE = `${API_URL}/attendance/index/`;
export const EMPLOYEE_ATTENDANCE_DAILY = `${API_URL}/attendance/`;
export const EMPLOYEE_ATTENDANCE_LOG = `${API_URL}/attendance/attendance_log/`;
export const EMPLOYEE_ATTENDANCE_LOG_CHART = `${API_URL}/attendance/attendance_log/chart/`;
export const EMPLOYEE_ATTENDANCE_HOVER = `${API_URL}/attendance/status_hover/`;
export const EMPLOYEE_DAY_ATTENDANCE = `${API_URL}/attendance/day/`;
export const REPORTING_MANAGER = `${API_URL}/master/employee/manager/`;
export const REIMBURSEMENTS_CONFIG = `${API_URL}/master/reimbursement_type/`;
export const MASTER_LEAVE_MANAGE = `${API_URL}/master/leave/manage/`;
export const EMPLOYEE_REMOVE = `${API_URL}/master/employee/remove/`;
export const USER_VALIDATION = `${API_URL}/employee/search_user_name/`;
export const SALARY_DEDUCTION = `${API_URL}/configuration/salary_deductions/`;
export const APPROVAL_HIERARCHY = `${API_URL}/configuration/approval_hierarchy/`;
export const PAYROLL_PERIOD = `${API_URL}/configuration/payroll_period/`;
export const MASTER_TDS = `${API_URL}/master/tds/`;
export const MASTER_ASSET = `${API_URL}/master/asset/`;
export const ASSET = `${API_URL}/asset/`;
export const ASSET_DETAILS = `${API_URL}/asset/details/`;
export const FORGORT_PASSWORD = `${API_URL}/authentication/forgotpassword/`;
export const CHANGE_PASSWORD = `${API_URL}/authentication/changepassword/`;
export const SEND_OTP = `${API_URL}/authentication/send_otp/`;
export const VERIFY_OTP = `${API_URL}/authentication/verify_otp/`;
export const SWITCH_USER = `${API_URL}/authentication/switch_user/`;
export const MASTER_TIMESHEET = `${API_URL}/attendance/timesheet/`;
export const MASTER_SALARY = `${API_URL}/master/salary/`;
export const DASHBOARD_ETYPE = `${API_URL}/dashboard/employer/employee_type/`;
export const DASHBOARD_LEAVE = `${API_URL}/dashboard/employer/leave/`;
export const DASHBOARD_BA = `${API_URL}/dashboard/employer/birthday/`;
export const EMPLOYEE_GET_REIMBURSEMENTS = `${API_URL}/employee/reimbursement_request/`;
export const EMPLOYEE_GET_ADVANCE_SALARY = `${API_URL}/employee/advance_salary_request/`;
export const LETTER_TYPE_API = `${API_URL}/master/letter_type/`;
export const EMPLOYEE_ISSUED_DOCUMENT = `${API_URL}/employee/document/issued/`;

//CONFIGURATION
export const SUBORDINATE_SETUP = `${API_URL}/configuration/subordinate/`;
export const SUBORDINATE_CARD_LIST = `${API_URL}/configuration/cards/`;
export const CUSTOM_CONFIGURATION = `${API_URL}/configuration/custom_configuration/`;
export const SALARY_COMPONENT = `${API_URL}/configuration/salary_component/`;
export const SALARY_TEMPLATE = `${API_URL}/configuration/salary_template/`;

// PAYROLL
export const PAYROLL_ADVANCE_SALARY_RULES = `${API_URL}/payroll/advance_salary/rules/`;
export const PAYROLL_REIMBURSEMENT_RULES = `${API_URL}/payroll/reimbursement/rules/`;
export const PAYROLL_DASHBOARD_SUMMARY_TOP = `${API_URL}/payroll/index/`;
export const PAYROLL_DASHBOARD_SUMMARY = `${API_URL}/payroll/summary/`;
export const PAYROLL_RUN_GET = `${API_URL}/payroll/run_payroll/`;
export const PAYROLL_PAY_SLIP = `${API_URL}/payroll/payslip/`;
export const PAYROLL_SUMMARY_EXPORT = `${API_URL}/payroll/export/`;
export const PAYROLL_SCHEDULE_PAYSLIP = `${API_URL}/payroll/schedule/`;
export const PAYROLL_PROCESS = `${API_URL}/payroll/payroll_process/`;
export const PAYROLL_PUBLISH = `${API_URL}/payroll/publish_payslip/`;
export const PAYROLL_PAYSLIP_EDIT = `${API_URL}/payroll/salary_edit/`;
export const RESIGNATION_APPROVAL = `${API_URL}/employee/resignation_approval/`;

// Employees End Point
export const EMPLOYEE_ADVANCE_SALARY = `${API_URL}/employee/advance_salary/request/`;
export const EMPLOYEE_ADVANCE_SALARY_APPROVE = `${API_URL}/employee/advance_salary/approve/`;
export const EMPLOYEE_REIMBURSEMENT_APPROVE = `${API_URL}/employee/reimbursements/approval/`;
export const EMPLOYEE_REIMBURSEMENTS = `${API_URL}/employee/reimbursements/`;
export const EMPLOYEE_DASHBOARD = `${API_URL}/dashboard/employee/`;
export const EMPLOYEEPROFILE = `${API_URL}/employee/profile`;
export const EMPLOYEE_BASICINFO = `${API_URL}/employee/basic_info/`;
export const EMPLOYEEBANK = `${API_URL}/employee/bank/`;
export const EMPLOYEE_WORK_EXPERIENCE = `${API_URL}/employee/experience/`;
export const EMPLOYEEDOC = `${API_URL}/employee/document/`;
export const EMPLOYEE_DAILY_ATTENDANCE = `${API_URL}/employee/check_in/out/`;
export const EMPLOYEE_LEAVE = `${API_URL}/employee/leave_request/`
export const EMPLOYEE_LEAVE_INFO = `${API_URL}/employee/leaveinfo/`
export const EMPLOYEE_LEAVE_APPROVAL = `${API_URL}/employee/leave/approve/`
export const EMPLOYEE_TIMESHEET = `${API_URL}/employee/timesheet/add/`
export const EMPLOYEE_TIMESHEET_APPROVAL = `${API_URL}/employee/timesheet/approve/`
export const EMPLOYEE_TASK = `${API_URL}/employee/task/add/`;
export const EMPLOYEE_CHECK_IN_OUT = `${API_URL}/employee/check_in/out/`;
export const EMPLOYEE_TDS = `${API_URL}/employee/tds/`;
export const EMPLOYEE_PAYSLIP = `${API_URL}/employee/payslip/`;
export const EMPLOYEE_DAILYATTENDANCE = `${API_URL}/employee/daily/`;
export const EMPLOYEE_DASHBOARD_BA = `${API_URL}/dashboard/employee/birthday/`;
export const EMPLOYEE_DASHBOARD_LEAVE = `${API_URL}/dashboard/employee/leave/`;
export const EMPLOYEE_DASHBOARD_PAYROLL = `${API_URL}/dashboard/employee/payroll/`;
export const EMPLOYEE_LEAVE_TYPE = `${API_URL}/employee/leavetype/`;
export const EMPLOYEE_PROFILE_IMAGE = `${API_URL}/employee/profile/`;
export const EMPLOYEE_SUBORDINATE_EMPLOYEELIST= `${API_URL}/employee/subordinates/employee_list/`;
export const EMPLOYEE_SUBORDINATE_ATTENDANCELIST= `${API_URL}/employee/subordinates/attendance_list/`;
export const EMPLOYEE_SUBORDINATE_MONTHLY_ATTENDANCELIST= `${API_URL}/employee/subordinates/monthly_attendance_list/`;
export const EMPLOYEE_SUBORDINATE_SUBORDINATESLIST= `${API_URL}/employee/subordinates/subordinates_list/`;
export const EMPLOYEE_TDS_DECLARATION = `${API_URL}/employee/tds_declaration/`;
export const EMPLOYEE_CANDIDATE_INFO = `${API_URL}/employee/candidate/info/`;
export const EMPLOYEE_CANDIDATE_DOCUMENT_INFO = `${API_URL}/employee/candidate/document/`;
export const EMPLOYEE_CANDIDATE_SALARY_INFO = `${API_URL}/employee/candidate/salary/`;
export const EMPLOYEE_CANDIDATE_FINAL_INFO = `${API_URL}/employee/candidate/verification/`;
export const EMPLOYEE_RESIGNATION_REQUEST= `${API_URL}/employee/resignation_request/`;
export const EMPLOYEE_APPROVER = `${API_URL}/employee/resignation_approver/`;
export const RESIGNATION_DETAILS = `${API_URL}/employee/resignation_process/`;
export const EMPLOYEE_RESIGNATION_APPROVAL =`${API_URL}/employee/resignation_approval/`;
 

//Admin End Points
export const ADMIN_USER_GROUP = `${API_URL}/administrator/user_group/`;
export const ADMIN_NOTICE_BOARD = `${API_URL}/administrator/notice_board/`;
export const ADMIN_USER_PERMISSION = `${API_URL}/administrator/user_permissions/`;
export const ADMIN_PASSWORD_POLICY = `${API_URL}/administrator/password_policy/`;
export const ADMIN_DOCUMENTS = `${API_URL}/administrator/documents`;

//Asset Management End Points
export const ASSET_REGISTRY = `${API_URL}/asset_management/asset_registry/`;
export const ASSET_EMPLOYEE_AUDIT = `${API_URL}/asset_management/employee_audit/`;

// Home 
export const HOME = `${API_URL}/home/`;
export const HAPPYMETER = `${API_URL}/home/happy_meter/`;
export const CLOCKIFY = `${API_URL}/home/clockify/`;
export const NOTIFICATION = `${API_URL}/home/notification/`;
export const NOTIFICATION_COUNT = `${API_URL}/home/notification/count/`;

//asset registry city - floor - block - room 
export const ASSET_CITY = `${API_URL}/asset_management/asset_city/`;
export const ASSET_BLOCK = `${API_URL}/asset_management/asset_block/`;
export const ASSET_FLOOR = `${API_URL}/asset_management/asset_floor/`;
export const ASSET_ROOM = `${API_URL}/asset_management/asset_room/`;

//employee End Point
export const PAYROLL_LEAVE_APPROVAL = `${API_URL}/employee/leave_approval/`;
export const PAYROLL_ADVANCE_SALARY_APPROVAL = `${API_URL}/employee/advance_salary_approval/`;
export const PAYROLL_REIMBURSEMENT_APPROVAL = `${API_URL}/employee/reimbursement_approval/`;
export const PAYROLL_APPROVAL_INBOX = `${API_URL}/employee/recent_approvals/`

//reports end points
export const EMPLOYEE_PF_REPORTS = `${API_URL}/reports/pf/`;
export const EMPLOYEE_ESI_REPORTS = `${API_URL}/reports/esi`;
export const PAYROLL_DETAIL_PERIOD_REPORTS = `${API_URL}/reports/payroll/`;
export const SALARY_PROCESS_BANK_REPORTS = `${API_URL}/reports/bank/`;
export const DAY_WISE_ATTENDANCE_REPORTS = `${API_URL}/reports/attendance/day/`;
export const EARNING_DEDUCTION_REPORTS = `${API_URL}/reports/credit_debit/`;
export const MONTH_WISE_ATTENDANCE_REPORTS = `${API_URL}/reports/attendance/month/`;
export const SALARY_SUMMARY_REPORTS = `${API_URL}/reports/salary_summary/`;
export const MUSTER_ROLL_REPORTS = `${API_URL}/reports/muster_roll/`;
export const SUBORDINATES_REPORTS = `${API_URL}/reports/subordinates/`;
export const ATTENDANCE_CORRECTION_REPORTS = `${API_URL}/reports/attendance/correction/`;
export const ROSTERING_REPORT = `${API_URL}/reports/roster_report/`;
export const LEAVE_PERMISSION_REPORT = `${API_URL}/reports/approved_leaves_report/`

// rostering 
export const ROSTERING_GET = `${API_URL}/rostering/`;
export const INDIVIDUAL_ROSTERING_GET = `${API_URL}/rostering/rostering_rotational_shift/`;

// get postal pincode
export const POSTAL_PINCODE = "https://api.postalpincode.in/pincode/";

//approved leave list
export const APPROVAL_LEAVE_LIST = `${API_URL}/employee/approved_leaves_list/`;

//Employee Migration
export const EMPLOYEE_MIGRATION_LIST = `${API_URL}/data_migration/employee_migration/list/`
export const REPORTING_MANAGER_LIST = `${API_URL}/data_migration/employee_migration/reporting_manager/`
export const MIGRATIONDATA = `${API_URL}/data_migration/employee_migration/migrate/`;
export const EMPLOYEE_MIGRATION_TEMPLATE = `${API_URL}/data_migration/employee_migration/template/`;

//myplans
export const MYPLANS = `${API_URL}/my_plans/`;

//EMPLOYEE TRACKER
export const EMPLOYEE_TRACKER = `${API_URL}/tracker/employee/clockin/`;
