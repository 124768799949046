import { Card, Spin, Upload, Form, Button, Input, Table, Tooltip, Select, message, Modal } from "antd";
import ToastMsg from "components/common/ToastMsg";
import MyButton from "components/ui/Button/MyButton";
import React, { useEffect, useState, useRef, useCallback, memo } from "react";
import { imgUploadSize } from 'components/common/enum';
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from 'xlsx';
import { getEmployeeDesignation } from "hooks/api/masterApi/employeeDesignationApi";
import { getEmployeeAllDepartment } from "hooks/api/masterApi/employeeDepartmentApi";
import { getEmployeeGroup } from "hooks/api/masterApi/employeeGroupApi";
import { getEmployeeShift } from "hooks/api/configurationApi/employeeShiftApi";
import { getUserGroup } from "hooks/api/adminApi/userGroupApi";
import { getEmployeeType } from "hooks/api/masterApi/employeeTypeApi";
import { genderEnum, relationshipEnum, titleEnum, bloodGroupEnum } from "components/common/enum";
import { ImagePaths } from "utils/ImagePath";
import IconButton from "components/ui/Button/IconButton";
import { CommonDeletePopup } from "components/common/CommonComponent";
import { MIGRATIONDATA } from "constants";
import { getCSVData, getMethod } from "components/common/CommonFuntion";
import { passwordValidator } from "components/common/validation";
import { useLocation, useNavigate } from "react-router-dom";
import { EMPLOYEE_MIGRATION_TEMPLATE } from "constants";

const MemoizedInputField = React.memo(
  ({ name, value, maxLength, isInvalid, errorMessage, handleChange, index }) => {
    return (
      <Tooltip title={errorMessage}>
        <Input
          autoComplete="off"
          className={isInvalid ? "alertbox" : "validatebox"}
          type="text"
          name={name}
          value={value}
          maxLength={maxLength}
          onChange={(e) => handleChange(e.target.name, e.target.value, index)}
          data-testID={`${name}_${index}`}
        />
      </Tooltip>
    );
  },(prevProps, nextProps) => {
  // Only re-render if the value or error state changes
  return (
    prevProps.value === nextProps.value &&
    prevProps.isInvalid === nextProps.isInvalid &&
    prevProps.errorMessage === nextProps.errorMessage
  );
});

const MemoizedSelectCell = React.memo(({ renderSelect, name, value, options, optionKey, index, isInvalid, errorMessage }) => {
  return (
    <Tooltip title={errorMessage}>
    {renderSelect(name, value, options, optionKey, index, true, errorMessage)}
  </Tooltip>
    )
  }
  ,(prevProps, nextProps) => {
    // Only re-render if the value or error state changes
    return (
      prevProps.value === nextProps.value &&
      prevProps.isInvalid === nextProps.isInvalid &&
      prevProps.errorMessage === nextProps.errorMessage
    );
  });


function MigrationTable() {
  const [loadings, setLoadings] = useState(false);
  const [isNotifyPopup, setIsNotifyPopup] = useState(false)
  const [csvFile, setCSVfile] = useState("")
  const navigate = useNavigate();
  const prevPropsRef = useRef();
  const refs = useRef({});
  const [sheetData, setSheetData] = useState([]);
  const [sorting, setSorting] = useState("");
  const [errors, setErrors] = useState({});
  const [tabledata, setTableData] = useState("");
  const [validateerror, setValidateError] = useState(Array(tabledata?.length).fill(true));
  const [formData, setFormData] = useState({
    attachment: [],
    totalcount: 0,
    fileName: "",
  });
  const authtoken = sessionStorage.getItem("token");
  const [form] = Form.useForm();
  const tableContainerHeight = "72vh";
  const [designationList, setdesignation] = useState([]);
  const [departmentlist, setdepartment] = useState([]);
  const [employeeGroupList, setemployeegroup] = useState([]);
  const [eShift, setEShift] = useState([]);
  const [eType, setEType] = useState([]);
  const [userGroup, setUserGroup] = useState([]);
  const [validation, setValidation] = useState([]);
  const hideCursorRef = useRef([]);
  const [deleteItem, setDeleteClicked] = useState({ index: undefined, isDeleteClicked: false });
  const location = useLocation();
  const { editDetails , limit } = location.state || {};
  const [migratekey, setmigrate] = useState(null)
  const [disableProcess, setProcess] = useState(true);
  const [iscancelPop, setIsCancelPop] = useState(false);

  const handleClose = () => {
    setIsNotifyPopup(false);
  }

  const popupcancel = () => {
    setIsCancelPop(false);
  }

  const handleNavigate = () => {
    navigate("/datamigration/employeemigration/employeedetails", { state: { migration_id: migratekey } });
  }

  const handleBack = () => {
    navigate('/datamigration/employeemigration');
  }
  useEffect(() => {
    if (editDetails?.id != null) {
      setmigrate(editDetails?.id)
      getresponsemigrate(editDetails?.id)
    }
  }, [editDetails?.id])

  const NotificationPopup = () => {
    return (
      <Modal
        centered
        open={true}
        footer={null}
        width={"34vw"}
        className="custom-modal"
        closable={false}
      >
        <p id="common_weekoffTxt">Employee has been migrated successfully. Kindly map the Reporting Manager for the employee by clicking on 'Allocate Reporting Manager' button</p>
        <div className='flex justify-end items-center  mt-20'>
          <MyButton htmlType="button" label="Cancel" onClick={handleClose} outlined={true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />
          <MyButton htmlType="button" label="Allocate Reporting Manager" onClick={handleNavigate} loading={loadings} paddingX={"0 1.2vw"} />
        </div>
      </Modal>
    )
  }

  const cancelPopup = () => {
    return (
      <Modal
        centered
        open={true}
        footer={null}
        width={"25vw"}
        className="custom-modal"
        closable={false}
      >
        <p id="common_weekoffTxt">Are you sure want cancel this Process ?</p>
        <div className='flex justify-end items-center  mt-20'>
          <MyButton htmlType="button" label="NO" onClick={popupcancel} outlined={true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} />
          <MyButton htmlType="button" label="Yes" onClick={handleCancel} loading={loadings} paddingX={"0 1.2vw"} />
        </div>
      </Modal>
    )
  }

  const handleDownload = () => {
    const queryString = ""
    getCSVData(EMPLOYEE_MIGRATION_TEMPLATE, queryString)
  }

  useEffect(() => {
    if (editDetails?.id != null) {
      setmigrate(editDetails?.id)
      getresponsemigrate(editDetails?.id)
    }
  }, [editDetails?.id])

  /*call designation*/
  const getEmployeeDesignationList = async () => {
    try {
      const apiData = await getEmployeeDesignation(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setdesignation([...apiData.data])
      } else {
        setdesignation([])
      }
    } catch (error) {
      setdesignation([])
    }
  }
  /*call departments*/
  const getDepartmentList = async () => {
    try {
      const apiData = await getEmployeeAllDepartment(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setdepartment(apiData.data?.length > 0 ? [...apiData.data] : [])

      } else {
        setdepartment([])
      }
    } catch (error) {
      setdepartment([])
    }
  }

  /*call employee group*/
  const getEmpGrpList = async () => {
    try {
      const apiData = await getEmployeeGroup(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setemployeegroup(apiData.data?.length > 0 ? [...apiData.data] : [])
      } else {
        setemployeegroup([])
      }
    } catch (error) {
      setemployeegroup([])
    }
  }

  /* Employee shift */
  const employeeShift = async () => {
    try {
      const apiData = await getEmployeeShift(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setEShift(apiData.data?.length > 0 ? [...apiData.data] : [])
      } else {
        setEShift([])
      }
    } catch (error) {
      setEShift([])
    }
  };
  /* EMployee Type */
  const getEmployeeTypeList = async () => {
    setEType([])
    try {
      const apiData = await getEmployeeType(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setEType(apiData.data?.length > 0 ? [...apiData.data] : [])
      } else {
        setEType([])
      }
    } catch (error) {
      setEType([])
    }
  };

  const getUserGroupList = async () => {
    setUserGroup([])
    try {
      const apiData = await getUserGroup(`drop_down=True`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setUserGroup(apiData.data?.length > 0 ? [...apiData.data] : [])
      } else {
        setUserGroup([])
      }
    } catch (error) {
      setUserGroup([])
    }
  };


  const getresponsemigrate = async (id) => {
    try {
      setLoadings(true)
      const apiData = await getMethod(`${MIGRATIONDATA}?migration_id=${id ? id : migratekey}`);
      if (apiData && apiData.status === "success" && apiData.data) {
        setTableData([...apiData.data.employee_data]);
        setFormData({
          ...formData,
          conflict_no: apiData.data.conflict_records,
          processed_no: apiData.data.processed_records,
        })
        setLoadings(false)
      } else {
        setTableData([]);
        setLoadings(false)
      }
    } catch (error) {
      setTableData([]);
      setLoadings(false)
    }
  }

  useEffect(() => {
    getDepartmentList();
    getEmpGrpList();
    getEmployeeDesignationList();
    employeeShift();
    getEmployeeTypeList();
    getUserGroupList();
  }, [])



  function parseAndFormatDate(dateString) {
    // Regular expression to check the format, allowing 1 or 2 digits for day/month
    const yyyyMmDdRegex = /^\d{4}[-/](0[1-9]|1[0-2])[-/](0[1-9]|[12][0-9]|3[01])$/;
    const ddMmYyyyRegex = /^(0[1-9]|[12][0-9]|3[01])[-/](0[1-9]|1[0-2])[-/]\d{4}$/;

    let year, month, day;

    // If the format is yyyy/mm/dd
    if (yyyyMmDdRegex.test(dateString)) {
        [year, month, day] = dateString.split('-');
    } 
    // If the format is dd/mm/yyyy
    else if (ddMmYyyyRegex.test(dateString)) {
        [day, month, year] = dateString.split('-');
    } else {
        // Invalid format
        return dateString;
    }

    // Ensure the date is valid
    const date = new Date(year, month - 1, day); // JS months are 0-based

    if (date && date.getFullYear() === parseInt(year) &&
        date.getMonth() + 1 === parseInt(month) &&
        date.getDate() === parseInt(day)) {

        // Return the date in yyyy/mm/dd format
        return `${year}-${padZero(month)}-${padZero(day)}`;
    } else {
        return dateString;
    }
  }

  // Helper function to pad numbers with leading zeros if needed
  function padZero(num) {
      return num.toString().padStart(2, '0');
  }

  const handleUploadChange = ({ fileList }) => {
    setTableData([])
    setLoadings(true)
    const validFileList = Array.isArray(fileList) ? fileList : [];
    setFormData({
      ...formData,
      attachment: validFileList,
    });

    if (validFileList?.length > 0) {

      const file = validFileList[0].originFileObj; // Access the actual file object (Blob)
      const reader = new FileReader();

      reader.onload = (event) => {
        const result = event.target.result;
        const workbook = XLSX.read(result, { type: 'binary' });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        setSheetData(data);

        const copyData = JSON.parse(JSON.stringify(data));
        const formattedData = [];

        // Get the headers from the first row
        const headers = copyData[2];
        if(headers[0] !== "Title *" && headers[1] !== "First Name *"){
          ToastMsg("error", "The format of the uploaded Excel sheet does not match the downloaded template. Please review and try again.");
          setLoadings(false)
          setTableData([])
          setFormData({
            ...formData,
            fileName: "",
          })
          return false
        }

        if (copyData?.length < 103) {
          if(copyData?.length > 3){
            // Loop through each row (starting from the second row)
          for (let i = 3; i < copyData?.length; i++) {
            const row = copyData[i];
            // Check if the row is completely empty (all values are falsy)
            const isEmptyRow = row.every(cell => cell === null || cell === undefined || cell === '');

            // Skip empty rows
            if (!isEmptyRow) {
              const rowObject = {};
              // rowObject["isError"] = validationProcess(row);
              for (let j = 0; j < row.length; j++) {
                let header = headers[j]; // Get the corresponding header for the current cell
                const cellData = row[j];   // Get the cell data

                if (header) {
                  header = header?.replace(/\*/g, '').replace(/\s+/g, ''); // Remove * and spaces
                }
                
                // If the header is related to date and cellData is a number, format it
                if ((typeof cellData === 'number' && header && header.toLowerCase().includes('date') ) || header === "DateOfJoining") {
                  rowObject[header] = XLSX.SSF.format('YYYY-MM-DD', cellData);
                  rowObject[header] = parseAndFormatDate(rowObject[header])
                } else if (typeof cellData === 'string') {
                  rowObject[header] = cellData.trim() || null;
                } else {
                  rowObject[header] = cellData ; // Assign the value to the header key, set null if empty
                }
              }
              rowObject["isError"] = validationProcess(rowObject);
              // Push the row object to the formatted data array
              formattedData.push(rowObject);
            }
          }
          }
          else{
            setFormData({
              ...formData,
              fileName: "",
            })
            ToastMsg("error", "There are no employee data available for migration.");
            setLoadings(false)
          }
        }
        else {
          setFormData({
            ...formData,
            fileName: "",
          })
          ToastMsg("error", "You can't Upload more than 100 employee details");
          setLoadings(false)
        }

        setTableData(formattedData); // Uncomment when needed
        setLoadings(false)
        // setUploaded(true); // Uncomment when needed
      };
      reader.readAsBinaryString(file); // Pass the single file (Blob) to FileReader
    }
  };

  const handleChange = (name, value, index) => {
    
    const updatedDataSource = [...tabledata]; // Clone the dataSource
    updatedDataSource[index][name] = value;
    updatedDataSource[index]["isError"] = validationProcess(updatedDataSource[index])
    setTableData(updatedDataSource)
  }

  const handleDeleteClicked = (index) => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      index: index,
      isDeleteClicked: true
    }));
  }

  const handleCancelBtn = () => {
    setDeleteClicked((prevdata) => ({
      ...prevdata,
      index: undefined,
      isDeleteClicked: false
    }));
  }

  const deleteRow = (index) => {
    const newData = [...tabledata];
    newData.splice(index, 1); // Remove the row at the given index
    setTableData(newData);

    setDeleteClicked((prevdata) => ({
      ...prevdata,
      index: undefined,
      isDeleteClicked: false
    }));
  };
  const renderSelect = (statekey, value, list = [], showKey = "text", index, required = false, errorMessage = "") => {
    if (!hideCursorRef.current[statekey]) {
      hideCursorRef.current[statekey] = React.createRef();
    }

    // Check if the current value exists in the list
    const isValidValue = list.some(option => option[showKey] === value);

    return (
      <div>
        <Tooltip title={errorMessage}>
          <Select
            showSearch
            value={isValidValue ? value : ""}
            name={statekey}
            className="pl-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            onChange={(value) => handleChange(statekey, value, index)}
            filterOption={(input, option) => {
              const childrenText = option.children;
              return typeof childrenText === 'string' &&
                childrenText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            ref={hideCursorRef.current[statekey]}
            onSelect={() => {
              hideCursorRef.current[statekey].current.blur();
            }}
            required
            style={{ border: isValidValue ? "1px solid #a0a2a4" : "1px solid red", borderRadius: "3px", boxShadow: isValidValue ? "0 0 7px -2px rgb(188 187 187)" : "0 0 7px -2px rgb(188 187 187)" }}
          >
            {
              list?.length > 0 && list.map((option) => (
                <Select.Option key={option[showKey]} value={option[showKey]}>
                  {option[showKey]}
                </Select.Option>
              ))}
          </Select>
        </Tooltip>
      </div>
    )
  }

  const handleTableChange = (pagination, sorter) => {
    const sortorder = `sort=${sorter.order === "descend" ? "desc" : sorter.order === "ascend" ? "asc" : ""}&`
    const sortQuery = sorter.order ? `${sortorder}column=${sorter.columnKey}` : ""

    setSorting(sortQuery)
  }

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleprocess = () => {
    setLoadings(true);
    const updatedData = { data: tabledata, migration_id: migratekey }
    fetch(MIGRATIONDATA, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${authtoken}`,
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data.status === "success") {
          message.success(data.message);
          await delay(1000);
          setFormData({
            ...formData,
            fileName: ""
          })
          setmigrate(data.migration_id)
          getresponsemigrate(data?.migration_id)
          setLoadings(false);
        } else if (data.status === "fail") {
          message.warning(data.message);
          setLoadings(false);
        }
      })
      .catch((error) => {
        error.json().then((data) => {
          setErrors(data);
          setLoadings(false);
        });
      });
    // setTimeout(() => {
    //   setLoadings(false);
    // }, 3000); // 2000ms = 2 seconds
  };

  const handlemigrate = () => {
    setLoadings(true);
    const updatedData = { data: tabledata, migrate: true, migration_id: migratekey };  // Add "migrate: true" to tabledata

    fetch(MIGRATIONDATA, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${authtoken}`,
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data.status === "success") {
          message.success(data.message);
          await delay(1000);
          setIsNotifyPopup(true)
          setLoadings(false);
        } else if (data.status === "fail") {
          message.warning(data.message);
        }
      })
      .catch((error) => {
        error.json().then((data) => {
          setErrors(data);
          setLoadings(false);
        });
      });
    // setTimeout(() => {
    //   setLoadings(false);
    // }, 3000); // 2000ms = 2 seconds
  };

  const passwordValidator = (_, value) => {
    let userPasswordPolicy = JSON.parse(sessionStorage.getItem("passwordPolicy"));
    let passwordData = Object.keys(userPasswordPolicy)?.length > 0 ? userPasswordPolicy : {};

    if (!value) {
      return "This field is required";
    }

    if (!(passwordData.min_length <= value.length && value.length <= passwordData.max_length)) {
      return `Password should be between ${passwordData.min_length} and ${passwordData.max_length} characters`;
    }

    const numMatches = (value.match(/[0-9]/g) || []).length;
    if (!(passwordData.min_number <= numMatches && numMatches <= passwordData.max_number)) {
      return `Password should contain ${passwordData.min_number} to ${passwordData.max_number} numeric characters`;
    }

    const lowerMatches = (value.match(/[a-z]/g) || []).length;
    if (!(passwordData.min_lower <= lowerMatches && lowerMatches <= passwordData.max_lower)) {
      return `Password should contain ${passwordData.min_lower} to ${passwordData.max_lower} lowercase characters`;
    }

    const upperMatches = (value.match(/[A-Z]/g) || []).length;
    if (!(passwordData.min_upper <= upperMatches && upperMatches <= passwordData.max_upper)) {
      return `Password should contain ${passwordData.min_upper} to ${passwordData.max_upper} uppercase characters`;
    }

    const alphaMatches = lowerMatches + upperMatches;
    if (!(passwordData.min_chars <= alphaMatches && alphaMatches <= passwordData.max_chars)) {
      return `Password should contain ${passwordData.min_chars} to ${passwordData.max_chars} alphabetic characters`;
    }

    const specialMatches = value.match(/[^A-Za-z0-9]/g);
    if (!passwordData.password_combination && specialMatches) {
      return 'Password should not contain any special characters';
    }

    return null;  // No error
  };

  const validationProcess = (record) => {
    let errorKey = false;
    let title = record?.Title?.toString().trim();
    if (!titleEnum.some(option => option["text"] === title)) {
      errorKey = true
    }
    if (!record?.FirstName || record?.FirstName?.length > 50) {
      errorKey = true
    }
    if (!record?.LastName || record?.LastName?.length > 50) {
      errorKey = true
    }
    if (!record?.PersonalEmailID || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(record?.PersonalEmailID.toString())) {
      errorKey = true
    }
    if (!record?.PersonalMobileNo || !/^\d{10}$/.test(record?.PersonalMobileNo.toString().trim())) {
      errorKey = true
    }
    if (!record?.ProfessionalEmailID || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(record?.ProfessionalEmailID.toString())) {
      errorKey = true
    }
    if (!designationList.some(option => option["designation_name"] === record?.Designation?.toString().trim())) {
      errorKey = true
    }
    if (!departmentlist.some(option => option["department_name"] === record?.Department?.toString().trim())) {
      errorKey = true
    }
    if (!eType.some(option => option["employee_type_name"] === record?.EmployeeType?.toString().trim())) {
      errorKey = true;
    }
    if (!eShift.some(option => option["shift_name"] === record?.Shift?.toString().trim())) {
      errorKey = true
    }
    const date = parseAndFormatDate(record?.DateOfJoining)
    if (!record?.DateOfJoining || !/^\d{4}[-/](0[1-9]|1[0-2])[-/](0[1-9]|[12][0-9]|3[01])$/.test(date.toString())) {
      errorKey = true
    }
    if (!record?.City || record?.City?.length > 50) {
      errorKey = true
    }
    if (!record?.State || record?.State?.length > 50) {
      errorKey = true
    }
    if (!record?.Pincode || record?.Pincode?.length > 50) {
      errorKey = true
    }
    if (!record?.PresentAddressCity || record?.PresentAddressCity?.length > 50) {
      errorKey = true
    }
    if (!record?.PresentAddressState || record?.PresentAddressState?.length > 50) {
      errorKey = true
    }
    if (!record?.PresentAddressPincode || record?.PresentAddressPincode?.length > 50) {
      errorKey = true
    }
    if (!userGroup.some(option => option["user_group_name"] === record?.Usergroup?.toString().trim())) {
      errorKey = true;
    }
    if (!record?.Username || record?.Username?.length > 50) {
      errorKey = true;
    }
    if (passwordValidator("", record?.Password)) {
      errorKey = true;
    }
    if (!genderEnum.some(option => option["text"] === (typeof record?.Gender === "string" ? record.Gender.trim() : record?.Gender))) {
      errorKey = true;
    }
    if (!record?.ContactPersonName || record?.ContactPersonName?.length > 50) {
      errorKey = true
    }

    if (!relationshipEnum.some(option => option["text"] === record?.RelationshipType?.toString().trim())) {
      errorKey = true
    }
    if (!record?.ContactPersonMobileNo || !/^\d{10}$/.test(record?.ContactPersonMobileNo.toString().trim())) {
      errorKey = true
    }
    if (!employeeGroupList.some(option => option["employee_group_name"] === record?.EmployeeGroup?.toString().trim())) {
      errorKey = true;
    }
    if (!bloodGroupEnum.some(option => option["text"] === record?.BloodGroup?.toString().trim())) {
      errorKey = true
    }

    if (!record?.EmergencyContactPerson || record?.EmergencyContactPerson?.length > 50) {
      errorKey = true
    }
    if (!relationshipEnum.some(option => option["text"] === record?.EmergencyRelationshipType?.toString().trim())) {
      errorKey = true
    }
    if (!record?.EmergencyContactNo || !/^\d{10}$/.test(record?.EmergencyContactNo.toString().trim())) {
      errorKey = true
    }
    return errorKey
  }
    const handleCancel = () => {
    setFormData({
      attachment: [],
      totalcount: 0,
      fileName: "",
      processed_no: 0,
      conflict_no: 0,
    });
    setmigrate(null)
    setProcess(true);
    setTableData([])
    setIsCancelPop(false);
  }

// Memoized cell component for input fields
// const MemoizedInputCell = memo(({ isInvalid, errorMessage, value, name, maxLength, index, handleChange }) => (
//   <Tooltip title={errorMessage}>
//     <Input
//       autoComplete="off"
//       className={isInvalid ? 'alertbox' : 'validatebox'}
//       type="text"
//       name={name}
//       value={value}
//       maxLength={maxLength}
//       onChange={(e) => handleChange(e.target.name, e.target.value, index)}
//       data-testID={`${name}_${index}`}
//     />
//   </Tooltip>
// ));

// Memoized cell component for select fields
// const MemoizedSelectCell = memo(({ renderSelect, name, value, options, optionKey, index, isInvalid, errorMessage }) => (
//   <Tooltip title={errorMessage}>
//     {renderSelect(name, value, options, optionKey, index, true, errorMessage)}
//   </Tooltip>
// ));

  const columns  = (handleChange) => [
    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
      width: "100px",
      render: (_, record, index) =>{
        const isInvalid = (!record?.Title || record?.Title?.length > 5);
        const errorMessage = (record?.Title?.length > 5 ? "Select Title" : isInvalid ? "Invalid Title" : null);
        return (
          <MemoizedSelectCell
          renderSelect={renderSelect}
          name="Title"
          value={record?.Title?.toString().trim()}
          options={titleEnum}
          optionKey="text"
          index={index}
          isInvalid={isInvalid}
          errorMessage={errorMessage}
        />
        )
      }
    },
    {
      title: "First Name",
      dataIndex: "FirstName",
      key: "FirstName",
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = (!record?.FirstName || record?.FirstName?.length > 50);
        const errorMessage = (record?.FirstName?.length > 50 ? "First Name should not exceed 50 character" : isInvalid ? "First Name should not be empty" : null);
        return (
          <MemoizedInputField
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          value={record?.FirstName}
          name="FirstName"
          maxLength={50}
          index={index}
          handleChange={handleChange}
        />
        );
      }
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
      key: "LastName",
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = (!record?.LastName || record?.LastName?.length > 50);
        const errorMessage = (record?.LastName?.length > 50 ? "Last Name should not exceed 50 character" : isInvalid ? "Last Name should not be empty" : null);
        return (
          <MemoizedInputField
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          value={record?.LastName}
          name="LastName"
          maxLength={50}
          index={index}
          handleChange={handleChange}
        />
        )
      }
    },
    {
      title: "Personal Mail Id",
      dataIndex: "PersonalEmailID",
      key: "PersonalEmailID",
      width: "150px",
      render: (_, record, index) => {
        const MailComment = record?.comments?.find(comment => comment.key === "personal_email_id");
        const isInvalid = MailComment?.value == record?.PersonalEmailID || !record?.PersonalEmailID || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(record?.PersonalEmailID.toString()) || !!MailComment;  // True if there's a validation comment
        const errorMessage = isInvalid
          ? (MailComment?.message || "Invalid Email id")  // Default error message if no comment
          : null;
        return (
          <MemoizedInputField
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          value={record?.PersonalEmailID}
          name="PersonalEmailID"
          maxLength={50}
          index={index}
          handleChange={handleChange}
        />
        )
      }
    },
    {
      title: "Personal Mobile No",
      dataIndex: "PersonalMobileNo",
      key: "PersonalMobileNo",
      width: "150px",
      render: (_, record, index) => {
        const MobileComment = record?.comments?.find(comment => comment.key === "personal_mobile_no");
        const isInvalid = MobileComment?.value == record?.PersonalMobileNo || !record?.PersonalMobileNo || !/^\d{10}$/.test(record?.PersonalMobileNo.toString().trim()) || !!MobileComment;  // True if there's a validation comment
        const errorMessage = isInvalid
          ? (MobileComment?.message || "Mobile No should be in 10 digit Numbers")  // Default error message if no comment
          : null;

        return (
          <MemoizedInputField
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          value={record?.PersonalMobileNo}
          name="PersonalMobileNo"
          maxLength={50}
          index={index}
          handleChange={handleChange}
        />
        )
      }
    },
    {
      title: "Professional Mail Id",
      dataIndex: "ProfessionalEmailID",
      key: "ProfessionalEmailID",
      width: "150px",
      render: (_, record, index) => {
        const MailComment = record?.comments?.find(comment => comment.key === "personal_email_id");
        const isInvalid = MailComment?.value == record?.ProfessionalEmailID ||  !record?.ProfessionalEmailID || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(record?.ProfessionalEmailID.toString()) || !!MailComment;  // True if there's a validation comment
        const errorMessage = isInvalid
          ? (MailComment?.message || "Invalid Email id")  // Default error message if no comment
          : null;
        return (
          <MemoizedInputField
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          value={record?.ProfessionalEmailID}
          name="ProfessionalEmailID"
          maxLength={50}
          index={index}
          handleChange={handleChange}
        />
        )
      }
    },
    {
      title: "Department",
      dataIndex: "Department",
      key: "Department",
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = (!record?.Department);
        const errorMessage = (isInvalid ? "Department should not be empty" : null);
        return (
          <MemoizedSelectCell
          renderSelect={renderSelect}
          name="Department"
          value={record?.Department?.toString().trim()}
          options={departmentlist}
          optionKey="department_name"
          index={index}
          isInvalid={isInvalid}
          errorMessage={errorMessage}
        />
        )
      }
    },
    {
      title: "Designation",
      dataIndex: "Designation",
      key: "Designation",
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = (!record?.Designation);
        const errorMessage = (isInvalid ? "Designation should not be empty" : null);
        return (
          <MemoizedSelectCell
          renderSelect={renderSelect}
          name="Designation"
          value={record?.Designation?.toString().trim()}
          options={designationList}
          optionKey="designation_name"
          index={index}
          isInvalid={isInvalid}
          errorMessage={errorMessage}
        />
       )
      }
    },
    {
      title: "Employee Type",
      dataIndex: "EmployeeType",
      key: "EmployeeType",
      width: "160px",
      render: (_, record, index) => {
        const isInvalid = (!record?.EmployeeType);
        const errorMessage = (isInvalid ? "Employee Type should not be empty" : null);
        return (
          <MemoizedSelectCell
          renderSelect={renderSelect}
          name="EmployeeType"
          value={record?.EmployeeType?.toString().trim()}
          options={eType}
          optionKey="employee_type_name"
          index={index}
          isInvalid={isInvalid}
          errorMessage={errorMessage}
        />
       )
      }
    },
    {
      title: "Date Of Joining",
      dataIndex: "DateOfJoining",
      key: "DateOfJoining",
      width: "120px",
      render: (_, record, index) => {

        const date = parseAndFormatDate(record?.DateOfJoining)
        const isInvalid = (!record?.DateOfJoining || !/^\d{4}[-/](0[1-9]|1[0-2])[-/](0[1-9]|[12][0-9]|3[01])$/.test(date.toString()));
        const errorMessage = (isInvalid ? "Invalid Date (YYYY-MM-DD)" : null)
        return (
          <MemoizedInputField
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          value={date}
          name="DateOfJoining"
          maxLength={12}
          index={index}
          handleChange={handleChange}
        />
        )
      }
    },
    {
      title: "Shift",
      dataIndex: "Shift",
      key: "Shift",
      width: "120px",
      render: (_, record, index) => {
         const isInvalid = !record?.Shift;
        const errorMessage= (isInvalid ? "Select Shfit" : null);
        return (
          <MemoizedSelectCell
          renderSelect={renderSelect}
          name="Shift"
          value={record?.Shift?.toString().trim()}
          options={eShift}
          optionKey="shift_name"
          index={index}
          isInvalid={isInvalid}
          errorMessage={errorMessage}
        />          
        )
      }
    },
    {
      title: "City",
      dataIndex: "City",
      key: "City",
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = !record?.City || record?.City?.length > 50;
        const errorMessage= (isInvalid ? "City Should not Exceed 50 Character" : null );
        return (
          <MemoizedInputField
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          value={record?.City}
          name="City"
          maxLength={50}
          index={index}
          handleChange={handleChange}
        />
        )
      }
    },
    {
      title: "State",
      dataIndex: "State",
      key: "State",
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = !record?.State || record?.State?.length > 50;
        const errorMessage = (isInvalid ?  "State Should not Exceed 50 Character" : null);
        return (
          <MemoizedInputField
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          value={record?.State}
          name="State"
          maxLength={50}
          index={index}
          handleChange={handleChange}
        />
        )
      }
    },
    {
      title: "Pincode",
      dataIndex: "Pincode",
      key: "Pincode",
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = !record?.Pincode || !/^\d{6}$/.test(record?.Pincode.toString().trim());
        const errorMessage = (isInvalid ? "Pincode should be in 6 digit Numbers" : null );
        return (
          <MemoizedInputField
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          value={record?.Pincode}
          name="Pincode"
          maxLength={10}
          index={index}
          handleChange={handleChange}
        />
        )
      }
    },

    {
      title: "Present Address City",
      dataIndex: "PresentAddressCity",
      key: "PresentAddressCity",
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = !record?.PresentAddressCity || record?.PresentAddressCity?.length > 50;
        const errorMessage = (isInvalid ? "PresentAddressCity Should not Exceed 50 Character" : null);
        return (
          <MemoizedInputField
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          value={record?.PresentAddressCity}
          name="PresentAddressCity"
          maxLength={50}
          index={index}
          handleChange={handleChange}
          />
        )
      }
    },
    {
      title: "Present Address State",
      dataIndex: "PresentAddressState",
      key: "PresentAddressState",
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = !record?.PresentAddressState || record?.PresentAddressState?.length > 50;
        const errorMessage = (isInvalid ? "PresentAddressState Should not Exceed 50 Character" : null );
        return (
          <MemoizedInputField
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          value={record?.PresentAddressState}
          name="PresentAddressState"
          maxLength={50}
          index={index}
          handleChange={handleChange}
          />
        )
      }
    },
    {
      title: "Present Address Pincode",
      dataIndex: "PresentAddressPincode",
      key: "PresentAddressPincode",
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = !record?.PresentAddressPincode || !/^\d{6}$/.test(record?.PresentAddressPincode.toString().trim());
        const errorMessage = (isInvalid ? "PresentAddressPincodeshould be in 6 digit Numbers" : null );
        return (
          <MemoizedInputField
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          value={record?.PresentAddressPincode}
          name="PresentAddressPincode"
          maxLength={50}
          index={index}
          handleChange={handleChange}
          />
        )
      }
    },
    {
      title: "User group",
      dataIndex: "Usergroup",
      key: "Usergroup",
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = !record?.Usergroup;
        const errorMessage = (isInvalid ?  "Select Usergroup" : null );
        return (
          <MemoizedSelectCell
          renderSelect={renderSelect}
          name="Usergroup"
          value={record?.Usergroup?.toString().trim()}
          options={userGroup}
          optionKey="user_group_name"
          index={index}
          isInvalid={isInvalid}
          errorMessage={errorMessage}
        />
        )
      }
    },
    {
      title: "User Name",
      dataIndex: "Username",
      key: "Username",
      width: "150px",
      render: (_, record, index) => {
        const usernameComment = record?.comments?.find(comment => comment.key === "username");
        const isInvalid = usernameComment?.value == record?.Username || !record?.Username || record?.Username?.length > 50;  // True if there's a validation comment
        const errorMessage = isInvalid
          ? (usernameComment?.message || "User Name Should not Exceed 50 Characters")  // Default error message if no comment
          : null;  // No error message if valid
        // const isInvalid = !record?.Username || record?.Username?.length > 15;
        return (
          <MemoizedInputField
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          value={record?.Username}
          name="Username"
          maxLength={15}
          index={index}
          handleChange={handleChange}
          />
        )
      }
    },
    {
      title: "Password",
      dataIndex: "Password",
      key: "Password",
      width: "150px",
      render: (_, record, index) => {
        const errorMessage = passwordValidator(_, record?.Password);
        const isInvalid = !!errorMessage; // Check if there's an error
        // const errorMessage = isInvalid ? "Password is invalid!" : "";
        return (
          <MemoizedInputField
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          value={record?.Password}
          name="Password"
          index={index}
          handleChange={handleChange}
          />
        )
      }
    },
    {
      title: "Gender",
      dataIndex: "Gender",
      key: "Gender",
      width: "120px",
      render: (_, record, index) => {
        const isInvalid = !record?.Gender;
        const errorMessage = (isInvalid ? "Select Gender" : null);
        return (
          <MemoizedSelectCell
          renderSelect={renderSelect}
          name="Gender"
          value={record?.Gender?.toString().trim()}
          options={genderEnum}
          optionKey="text"
          index={index}
          isInvalid={isInvalid}
          errorMessage={errorMessage}
        />
        )
      }
    },
    {
      title: "Contact Person Name",
      dataIndex: "ContactPersonName",
      key: "ContactPersonName",
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = !record?.ContactPersonName || record?.ContactPersonName?.length > 50;
        const errorMessage = (isInvalid ? "Contact Person Name should be below 50 Characters" : null);
        return (
          <MemoizedInputField
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          value={record?.ContactPersonName}
          name="ContactPersonName"
          maxLength={50}
          index={index}
          handleChange={handleChange}
          />
        )
      }
    },
    {
      title: "Relationship",
      dataIndex: "RelationshipType",
      key: "RelationshipType",
      width: "120px",
      render: (_, record, index) => {
        const isInvalid = !record?.RelationshipType;
        const errorMessage = (isInvalid ?  "Select RelationshipType" : null );
        return (
          <MemoizedSelectCell
          renderSelect={renderSelect}
          name="RelationshipType"
          value={record?.RelationshipType?.toString().trim()}
          options={relationshipEnum}
          optionKey="text"
          index={index}
          isInvalid={isInvalid}
          errorMessage={errorMessage}
        />
        )
      }
    },
    {
      title: "Contact Person Mobile No",
      dataIndex: "ContactPersonMobileNo",
      key: "ContactPersonMobileNo",
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = !record?.ContactPersonMobileNo || !/^\d{10}$/.test(record?.ContactPersonMobileNo.toString().trim());
        const errorMessage = (isInvalid ?  "Mobile No should be in 10 digit Numbers" : null );
        return (
          <MemoizedInputField
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          value={record?.ContactPersonMobileNo}
          name="ContactPersonMobileNo"
          maxLength={10}
          index={index}
          handleChange={handleChange}
          />
        )
      }
    },
    {
      title: "Employee Group",
      dataIndex: "EmployeeGroup",
      key: "EmployeeGroup",
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = !record?.EmployeeGroup;
        const errorMessage = (isInvalid ? "Select EmployeeGroup" : null);
        return (
          <MemoizedSelectCell
          renderSelect={renderSelect}
          name="EmployeeGroup"
          value={record?.EmployeeGroup?.toString().trim()}
          options={employeeGroupList}
          optionKey="employee_group_name"
          index={index}
          isInvalid={isInvalid}
          errorMessage={errorMessage}
        />
        )
      }
    },
    {
      title: "Blood Group",
      dataIndex: "BloodGroup",
      key: "BloodGroup",
      width: "120px",
      render: (_, record, index) => {
        const isInvalid = !record?.BloodGroup;
        const errorMessage = (isInvalid ? "Select BloodGroup" : null );
        return (
          <MemoizedSelectCell
          renderSelect={renderSelect}
          name="BloodGroup"
          value={record?.BloodGroup?.toString().trim()}
          options={bloodGroupEnum}
          optionKey="text"
          index={index}
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          />
        )
      }
    },
    {
      title: "Emergency Contact Person",
      dataIndex: "EmergencyContactPerson",
      key: "EmergencyContactPerson",
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = !record?.EmergencyContactPerson || record?.EmergencyContactPerson?.length > 50;
        const errorMessage = (isInvalid ? "Conatct person name Should not Exceed 50 Character" : null );
        return (
          <MemoizedInputField
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          value={record?.EmergencyContactPerson}
          name="EmergencyContactPerson"
          maxLength={50}
          index={index}
          handleChange={handleChange}
          />
        )
      }
    },
    {
      title: "Emergency Contact No",
      dataIndex: "EmergencyContactNo",
      key: "EmergencyContactNo",
      width: "150px",
      render: (_, record, index) => {
        const isInvalid = !record?.EmergencyContactNo || !/^\d{10}$/.test(record?.EmergencyContactNo.toString().trim());
        const errorMessage = (isInvalid ?  "Emergency Contact No should be in 10 digit Numbers" : null );
        return (
          <MemoizedInputField
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          value={record?.EmergencyContactNo}
          name="EmergencyContactNo"
          maxLength={10}
          index={index}
          handleChange={handleChange}
          />
        )
      }
    },
    {
      title: "Emergency Relationship",
      dataIndex: "EmergencyRelationshipType",
      key: "EmergencyRelationshipType",
      width: "120px",
      render: (_, record, index) => {
        const isInvalid = !record?.EmergencyRelationshipType;
        const errorMessage = (isInvalid ? "Select EmergencyRelationshipType" : null );
        return (
          <MemoizedSelectCell
          renderSelect={renderSelect}
          name="EmergencyRelationshipType"
          value={record?.EmergencyRelationshipType?.toString().trim()}
          options={relationshipEnum}
          optionKey="text"
          index={index}
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          />
        )
      }
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "100px",
      render: (_, record, index) => {
        return (
          <Tooltip title={"Delete"}>
            <IconButton
              title="Delete"
              icon={<img src={ImagePaths.delete.default} alt="Delete" />}
              onClick={() => (handleDeleteClicked(index))}
              testID={'ug_btn_delete'}
            />
          </Tooltip>
        );
      },
    },
  ];
  if (migratekey) {
    const commentdata = {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
      width: "150px",
      render: (_, record, index) => {
        const mergedComments = record.comments
          ?.map(comment => comment.message)
          .join(", ") || "";

        return (
          <Tooltip title={mergedComments}>
            <p>{mergedComments?.slice(0, 15) + "..."}</p>
          </Tooltip>
        );
      },
    }

    // Insert the Comment right before the last element
    columns(handleChange)?.splice(columns?.length - 1, 0, commentdata);
  }

  useEffect(() => {
    if (tabledata.length > 0) {
      let errorIndex = -1;
      for (let i = 0; i < tabledata.length; i++) {
        if (tabledata[i].isError === true) {
          errorIndex = i;
          break;
        }
      }
      if (errorIndex !== -1) {
        setProcess(true)
      } else {
        setProcess(false)
      }
    }
  }, [tabledata])


  return (
    <div className="h-full">
      <Card className="p-3">
        {/* {loadings && (
                    <div className="loaderOverlay">
                        <Spin />
                    </div>
                )} */}

        <div className='flex items-center'>
          <Tooltip title={"Back"} className="mr-2" placement="bottom">
            <img style={{ cursor: "pointer" }} src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBack} />
          </Tooltip>
          <p className='common_tltTxt'>{`Employees (${tabledata?.length})`}</p>
        </div>
        {(tabledata.length > limit ) && <label className="conflictrec" style={{float:"inline-end"}}> You Can only process {limit} employees</label>}
        <div className="mt-7 flex align-center justify-between" >
          <div className="flex gap-5">
            <MyButton onClick={handleDownload} label={"Download Sheet"} />
            <div className="flex items-center justify-between border border-gray-300 rounded-sm p-2 w-full" style={{ width: "20dvw", height: "6dvh" }}>
              <span style={{ width: "16dvw", overflow: "hidden" }} className="text-gray-500">{formData?.fileName ? formData?.fileName : "Browse Files"}</span>
              <Upload
                disabled={migratekey ? true : false}
                id={`notice_board_attachment`}
                maxCount={1}
                onChange={handleUploadChange}
                beforeUpload={(file) => {
                  setLoadings(true)
                  const isCSV = file.type === 'text/csv'; // Correct MIME type for CSV files
                  if (!isCSV) {
                    ToastMsg("error", "You can only upload CSV files!");
                    return false;
                  }
                  const isWithinSizeLimit = file.size / 1024 / 1024 < imgUploadSize;
                  if (!isWithinSizeLimit) {
                    ToastMsg("error", `File must be smaller than ${imgUploadSize}MB!`);
                    return false;
                  }
                  setFormData({
                    ...formData,
                    fileName: file.name,
                  })
                  return false;
                }}
                showUploadList={false}
                accept=".csv">
                <Button
                  type="text"
                  icon={<UploadOutlined />}
                  className="bg-white border-none"
                  style={{ border: "1px solid #afacac", paddingLeft: "7px", paddingTop: "2px" }}
                />
              </Upload>
            </div>
            {migratekey ?
              <div>
                <p class="processrec">Processed records : {formData?.processed_no}</p>
                <p class="conflictrec">Conflict records : {formData?.conflict_no}</p>
              </div> : null
            }
          </div>
          <div className="flex">
            <MyButton label={"Cancel"} onClick={() => setIsCancelPop(true)} />
            <MyButton className="ml-5" type="button" display= {editDetails?.status === "completed" ? "none" : "block"} disabled={(tabledata.length > limit ) || tabledata.length <= 0 || loadings || disableProcess} onClick={handleprocess} label={"Process"} bgColor={disableProcess ? "#d6d6d6" : "#334B49"} />
            <MyButton className="ml-5" onClick={editDetails?.status === "completed" ? handleNavigate : handlemigrate} style={{ backgroundColor: "grey" }} label={editDetails?.status === "completed" ? "Allocate Report Manager" : "Migrate"} bgColor={(tabledata.length > 0 || !migratekey || formData?.conflict_no != 0) ? "#d6d6d6" : "#334B49"} disabled={ (tabledata.length > limit ) || loadings || tabledata.length > 0 || !migratekey || formData?.conflict_no != 0} />
          </div>
        </div>
      </Card>
      <Card className="mt-2 h-full">
        <Table
          style={{ "--table-container-height": tableContainerHeight }}
          scroll={{ y: `calc(${tableContainerHeight} - 140px)` }}
          columns={columns(handleChange)}
          dataSource={tabledata?.length > 0 ? tabledata : null}
          loading={loadings}
          onChange={handleTableChange}
          pagination={false
          }
        />
        {deleteItem.isDeleteClicked ? <CommonDeletePopup handleCancelBtn={handleCancelBtn} handleDeleteBtn={() => deleteRow(deleteItem.index)} /> : null}
      </Card>
      {isNotifyPopup ? NotificationPopup() : null}
      {iscancelPop ? cancelPopup() : null}
    </div>
  )
}


const styles = {
  divStyle: {
    marginBottom: "1vw",
    paddingBottom: "1.5vw"
  },
  tltTxt: {
    paddingBottom: "0.75vw"
  },
  uploadInput: {
    flex: 1,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    paddingRight: 0,
  },
  uploadBtn: {
    height: "100%",
    boxShadow: 0,
    fontFamily: "Urbanist",
    backgroundColor: "#cbcbcb",
    borderColor: "#cbcbcb",
    border: "none",
    padding: "0.3vw",
    margin: 0,
    color: "#183433",
    fontSize: '0.85vw',
    fontWeight: "bold",
  },

}


export default MigrationTable;